<template>
  <div class="photo-gallery-card shadow-sm bg-white">
    <div
        class="photo-type">
        <img :src="absoluteUrl(photoType.value)" class="photo-type-img w-100">
    </div>
    <div class="photo-type-name p-2">
        <h6 class="m-0">{{photoType.name}}</h6>
        <!-- <small style="font-size:12px;line-height:1;">{{photoType.description}}</small> -->
    </div>
  </div>
</template>

<script>
export default {
    props:{
        photoType:{
            type:Object,
            required: true
        },
    }
    
}
</script>

<style>
.photo-gallery-card .photo-type {
    position: relative;
}
.photo-gallery-card .photo-type{
    background: var(--light);
    transition: border-width 0.3s ease-in;
}
</style>