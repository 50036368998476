<template>
   <div class="color-gallery-card shadow-sm bg-white position-relative">
        <div class="color-row d-flex">
            <div v-for="(swatch, sIndex) in getColorType" :key="sIndex"
            :style="{'background-color': swatch, 'width': `${100/getColorType.length}%`}"
            class="color-type-block">
            </div>
        </div>
        <div class="color-type-name p-2">
            <h6 class="m-0">{{colorType.name}}</h6>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        colorType:{
            type:Object,
            required: true
        },
    },
    computed:{
        getColorType(){
            return this.colorType.value.split(',')
        }
    }
}
</script>

<style>
.color-gallery-card{
    cursor: pointer;
}

.color-gallery-card .color-row{
    overflow: hidden;
    transition: border-width 0.3s ease-in;
}

.color-gallery-card {
    position: relative;
}

.color-gallery-card .color-type-block{
    height: 60px;
}
@media (max-width: 567px) {
    .color-gallery-card .color-type-block {
        height: 50px;
    }
}
</style>